import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  createContext,
} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { PrivateRoute } from '../../components/PrivateRoute';
import { Auth } from 'aws-amplify';
import { CreateVendor } from '../../pages/admin/vendor/CreateVendor/CreateVendor';
import { EditVendor } from '../../pages/admin/vendor/EditVendor/EditVendor';
import { ManageUser } from '../../pages/admin/user/ManageUser';
import { CreateUser } from '../../pages/admin/user/CreateUser/CreateUser';
import { EditUser } from '../../pages/admin/user/EditUser';
import { BrokerView } from '../../pages/admin/broker/BrokerView';
import { VendorAdminHome } from '../../components/VendorAdmin/VendorAdminHome';
import { MyProfile } from '../../pages/profile/MyProfile';
import { FAQs } from '../../pages/FAQs/faqs';
import { Training } from '../../pages/trainingpage/training';
import { Home } from '../../pages/reports/Home/Home';
import { QuickFacts } from '../../pages/reports/QuickFacts/QuickFacts';
import { VoidReport } from '../../pages/reports/VoidReport/VoidReport';
import { WeeklyTrend } from '../../pages/reports/WeeklyTrend/WeeklyTrend';
import { SupplierPerformance } from '../../pages/reports/SupplierPerformance/SupplierPerformance';
import { FourYearTrend } from '../../pages/reports/FourYearTrend/FourYearTrend';
import { ServiceAndInventory } from '../../pages/reports/ServiceAndInventory/ServiceAndInventory';
import { Produce } from '../../pages/reports/Produce/Produce';
import { Forecasts } from '../../pages/reports/Forecasts/Forecasts';
import { Compliance } from '../../pages/reports/Compliance';
import { Purchasing } from '../../pages/reports/PurchasingAndSales/Purchasing';
import { Sales } from '../../pages/reports/PurchasingAndSales/sales';
import {
  getCannedTimeframeArr,
  getDatesPreviousYear,
} from '../../utils/dateUtils';
import { useIdleTimer } from '../../utils/IdleTimer';
import { authorizeUser } from '../../utils/helpers';
import { CustomerDataSubscription } from '../../pages/reports/CustomerDataSubscription/CustomerDataSubscription';
import { Manage } from '../../components/VendorAdmin/Manage';
import usersService from '../../axios/usersService';
import HeaderAppBar from '../../layouts/HeaderAppbar';
import RoleError from '../../pages/errors/RoleError';
import Error from '../../pages/errors/Error';

export const FiltersContext = createContext();

export default function ApplicationRoutes() {
  const [filterPageOpen, setFilterPageOpen] = useState(false);
  const [embedRefreshTrigger, setEmbedRefreshTrigger] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [isReset, setIsReset] = useState(true);
  const [impersonatorInfo, setImpersonatorInfo] = useState({});
  const [viewType, setViewType] = useState('basic');
  const [basicFilters, setBasicFilters] = useState({});
  const [insightsFilters, setInsightsFilters] = useState({});
  const [customerFilters, setCustomerFilters] = useState({});
  const [purchaseFilters, setPurchaseFilters] = useState({});
  const [basicWorkbookControlData, setBasicWorkbookControlData] = useState({});
  const [insightsWorkbookControlData, setInsightsWorkbookControlData] =
    useState({});
  const [homeWorkbookControlData, setHomeWorkbookControlData] = useState({});
  const [quickFactsWorkbookControlData, setQuickFactsWorkbookControlData] =
    useState({});
  const [voidReportWorkbookControlData, setVoidReportWorkbookControlData] =
    useState({});
  const [weeklyWorkbookControlData, setWeeklyWorkbookControlData] = useState(
    {},
  );
  const [customerWorkbookControlData, setCustomerWorkbookControlData] =
    useState({});
  const [sldWorkbookControlData, setSldWorkbookControlData] = useState({});
  const [sipWorkbookControlData, setSipWorkbookControlData] = useState({});
  const [sifWorkbookControlData, setSifWorkbookControlData] = useState({});
  const [purchaseWorkbookControlData, setPurchaseWorkbookControlData] =
    useState({});
  const [salesWorkbookControlData, setSalesWorkbookControlData] = useState({});
  const [yearlyWorkbookControlData, setYearlyWorkbookControlData] = useState(
    {},
  );
  const [
    supplierPerformanceBasicWorkbookControlData,
    setSupplierPerformanceBasicWorkbookControlData,
  ] = useState({});
  const [
    supplierPerformanceInsightsWorkbookControlData,
    setSupplierPerformanceInsightsWorkbookControlData,
  ] = useState({});
  const [basicFiltersNameInput, setBasicFiltersNameInput] = useState('');
  const [insightsFiltersNameInput, setInsightsFiltersNameInput] = useState('');
  const [customerFiltersNameInput, setCustomerFiltersNameInput] = useState('');
  const [purchaseFiltersNameInput, setPurchaseFiltersNameInput] = useState('');
  const [sldWeekly, setSldWeekly] = useState(false);
  const [sipWeekly, setSipWeekly] = useState(false);
  const [sicWeekly, setSicWeekly] = useState(false);
  const [subId, setSubId] = useState(1);
  const [cannedReport, setCannedReport] = useState('52');
  const [currentTimeframe, setCurrentTimeframe] = useState(
    getCannedTimeframeArr(cannedReport),
  );
  const [previousTimeframe, setPreviousTimeframe] = useState(
    getDatesPreviousYear(cannedReport, currentTimeframe, false),
  );
  const [weekSelection, setWeekSelection] = useState(cannedReport);
  const [switchOn, setSwitchOn] = useState(false);
  const [embedDidLoad, setEmbedDidLoad] = useState(false);
  const [filterPageData, setFilterPageData] = useState({});

  const savedFiltersNameBasic = useRef(null);
  const savedFiltersNameInsights = useRef(null);
  const savedFiltersNameCustomer = useRef(null);
  const savedFiltersNamePurchase = useRef(null);

  // Here, the page isn't created so we need to get the current page with useLocation
  const location = useLocation();

  // var passed to FilterConfigMenu component to persist through modal close | open
  const savedFiltersName = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? savedFiltersNameCustomer
        : location.pathname.includes('/purchasing')
          ? savedFiltersNamePurchase
          : viewType === 'basic'
            ? savedFiltersNameBasic
            : savedFiltersNameInsights,
    [viewType, location.pathname],
  );
  const filters = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? customerFilters
        : location.pathname.includes('/purchasing')
          ? purchaseFilters
          : viewType === 'basic'
            ? basicFilters
            : insightsFilters,
    [
      viewType,
      location.pathname,
      basicFilters,
      insightsFilters,
      customerFilters,
      purchaseFilters,
    ],
  );
  const workbookControlData = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? customerWorkbookControlData
        : location.pathname.includes('/purchasing')
          ? purchaseWorkbookControlData
          : viewType === 'basic'
            ? basicWorkbookControlData
            : insightsWorkbookControlData,
    [
      viewType,
      location.pathname,
      basicWorkbookControlData,
      insightsWorkbookControlData,
      customerWorkbookControlData,
      purchaseWorkbookControlData,
    ],
  );
  const filtersNameInput = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? customerFiltersNameInput
        : location.pathname.includes('/purchasing')
          ? purchaseFiltersNameInput
          : viewType === 'basic'
            ? basicFiltersNameInput
            : insightsFiltersNameInput,
    [
      viewType,
      location.pathname,
      basicFiltersNameInput,
      insightsFiltersNameInput,
      customerFiltersNameInput,
      purchaseFiltersNameInput,
    ],
  );
  const setFilters = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? setCustomerFilters
        : location.pathname.includes('/purchasing')
          ? setPurchaseFilters
          : viewType === 'basic'
            ? setBasicFilters
            : setInsightsFilters,
    [viewType, location.pathname],
  );
  const setWorkbookControlData = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? setCustomerWorkbookControlData
        : location.pathname.includes('/purchasing')
          ? setPurchaseWorkbookControlData
          : viewType === 'basic'
            ? setBasicWorkbookControlData
            : setInsightsWorkbookControlData,
    [viewType, location.pathname],
  );
  const setFiltersNameInput = useMemo(
    () =>
      location.pathname.includes('/customer-data')
        ? setCustomerFiltersNameInput
        : location.pathname.includes('/purchasing')
          ? setPurchaseFiltersNameInput
          : viewType === 'basic'
            ? setBasicFiltersNameInput
            : setInsightsFiltersNameInput,
    [viewType, location.pathname],
  );

  const channel = new BroadcastChannel('shared-table');
  channel.addEventListener('message', () => {});
  channel.onmessage = function () {
    Auth.signOut();
  };

  const handleResetFilters = (page) => {
    setBasicFilters({});
    setInsightsFilters({});
    setCustomerFilters({});
    setPurchaseFilters({});
    switch (page) {
      case 'cdq':
        setQuickFactsWorkbookControlData({});
        break;
      case 'vr':
        setVoidReportWorkbookControlData({});
        break;
      case 'weekly':
        setWeeklyWorkbookControlData({});
        break;
      case 'customer-data':
        setCustomerWorkbookControlData({});
        break;
      case 'purchase':
        setPurchaseWorkbookControlData({});
        break;
      default:
        setQuickFactsWorkbookControlData({});
        setVoidReportWorkbookControlData({});
        setWeeklyWorkbookControlData({});
        setPurchaseWorkbookControlData({});
        setCustomerWorkbookControlData({});
        break;
    }

    setIsReset(!isReset);
    setFiltersNameInput(null);
    savedFiltersName.current = '';
  };

  //Validation of the user credentials
  useEffect(() => {
    authorizeUser(setIsAdmin, setRoleError, setViewType);
  }, []);

  useEffect(() => {
    async function fetchID() {
      const subIdRes = await usersService.getSubId();
      setSubId(subIdRes);
    }
    fetchID();
  }, []);

  useIdleTimer(channel);

  return (
    <>
      <FiltersContext.Provider
        value={{
          channel,
          isAdmin,
          savedFiltersName,
          subId,
          filtersNameInput,
          setFiltersNameInput,
          filters,
          setFilters,
          insightsWorkbookControlData,
          setInsightsWorkbookControlData,
          workbookControlData,
          setWorkbookControlData,
          homeWorkbookControlData,
          setHomeWorkbookControlData,
          quickFactsWorkbookControlData,
          setQuickFactsWorkbookControlData,
          voidReportWorkbookControlData,
          setVoidReportWorkbookControlData,
          weeklyWorkbookControlData,
          setWeeklyWorkbookControlData,
          sldWorkbookControlData,
          setSldWorkbookControlData,
          sipWorkbookControlData,
          setSipWorkbookControlData,
          sifWorkbookControlData,
          setSifWorkbookControlData,
          purchaseWorkbookControlData,
          setPurchaseWorkbookControlData,
          salesWorkbookControlData,
          setSalesWorkbookControlData,
          yearlyWorkbookControlData,
          setYearlyWorkbookControlData,
          customerWorkbookControlData,
          setCustomerWorkbookControlData,
          viewType,
          setViewType,
          isReset,
          setIsReset,
          sldWeekly,
          setSldWeekly,
          sicWeekly,
          setSicWeekly,
          sipWeekly,
          setSipWeekly,
          weekSelection,
          setWeekSelection,
          impersonatorInfo,
          setImpersonatorInfo,
          cannedReport,
          setCannedReport,
          currentTimeframe,
          setCurrentTimeframe,
          previousTimeframe,
          setPreviousTimeframe,
          switchOn,
          setSwitchOn,
          filterPageOpen,
          setFilterPageOpen,
          embedRefreshTrigger,
          setEmbedRefreshTrigger,
          supplierPerformanceBasicWorkbookControlData,
          setSupplierPerformanceBasicWorkbookControlData,
          supplierPerformanceInsightsWorkbookControlData,
          setSupplierPerformanceInsightsWorkbookControlData,
          handleResetFilters,
          embedDidLoad,
          setEmbedDidLoad,
          setSubId,
          filterPageData,
          setFilterPageData,
        }}
      >
        {roleError ? <RoleError /> : <HeaderAppBar />}
        <Routes>
          <Route path="/" element={<Home page={'home'} />} />
          <Route path="/quick-facts" element={<QuickFacts page={'cdq'} />} />
          <Route path="/void-report" element={<VoidReport page={'vr'} />} />
          <Route
            path="/weekly-trend"
            element={<WeeklyTrend page={'weekly'} />}
          />
          <Route
            path="/supplier-performance"
            element={<SupplierPerformance page={'fsr'} />}
          />
          <Route
            path="/4-year-trend"
            element={<FourYearTrend page={'yearly'} />}
          />
          <Route
            path="/service-and-inventory"
            element={<ServiceAndInventory page={'sld'} />}
          />
          <Route path="/produce" element={<Produce page={'sip'} />} />
          <Route path="/forecasts" element={<Forecasts page={'sif'} />} />
          <Route path="/compliance" element={<Compliance page={'compliance'} />} />
          <Route
            path="/purchasing"
            element={<Purchasing page={'purchase'} />}
          />
          <Route path="/sales" element={<Sales page={'sales'} />} />
          <Route
            path="/customer-data"
            element={<CustomerDataSubscription page={'customer-data'} />}
          />
          <Route element={<PrivateRoute isAdmin={isAdmin} />}>
            <Route path="/create-vendor" element={<CreateVendor />} />
            <Route path="/edit-vendor" element={<EditVendor />} />
            <Route path="/create-user" element={<CreateUser />} />
            <Route path="/manage-user" element={<ManageUser />} />
            <Route
              path="/manage-vendor"
              element={<Manage userGroup={'vendor'} />}
            />
            <Route
              path="/manage-broker"
              element={<Manage userGroup={'broker'} />}
            />
            <Route path="/vendor-admin" element={<VendorAdminHome />} />
            <Route path="/edit-user" element={<EditUser />} />
            <Route path="/add-broker" element={<BrokerView action={'add'} />} />
            <Route
              path="/edit-broker"
              element={<BrokerView action={'edit'} />}
            />
          </Route>
          <Route path="/profile" element={<MyProfile />} />
          <Route path="/error" element={<Error />} />
          <Route path="/faqs" element={<FAQs />} sx={{ overflow: 'scroll' }} />
          <Route path="/training" element={<Training />} />
        </Routes>
      </FiltersContext.Provider>
    </>
  );
}
