import React, { useState, useEffect, useContext, useRef } from 'react';
import usersService from '../axios/usersService';
import Loading from '../components/Loading';
import { Flex } from '@aws-amplify/ui-react';
import { getToggledFilters, removeTags } from '../utils/helpers';
import { encodeDates } from '../utils/dateUtils';
import { useEventListener } from '../hooks/useEventListener';
import { useUpdateWorkbookData } from '../hooks/useUpdateWorkbookData';
import { useFetchPeppers } from '../hooks/useFetchPeppers';
import { FiltersContext } from '../components/Navigation/Routes';
import {
  getWorksheetControlConfigs,
  getFormattedFilters,
  postIframeMessage,
} from '../utils/helpers';
import SigmaIframe from '../components/SigmaIframe';

export function SigmaEmbed({ page }) {
  const context = useContext(FiltersContext);
  const {
    homeWorkbookControlData,
    setHomeWorkbookControlData,
    quickFactsWorkbookControlData,
    setQuickFactsWorkbookControlData,
    voidReportWorkbookControlData,
    setVoidReportWorkbookControlData,
    weeklyWorkbookControlData,
    setWeeklyWorkbookControlData,
    sldWorkbookControlData,
    setSldWorkbookControlData,
    sifWorkbookControlData,
    setSifWorkbookControlData,
    sipWorkbookControlData,
    setSipWorkbookControlData,
    purchaseWorkbookControlData,
    setPurchaseWorkbookControlData,
    salesWorkbookControlData,
    setSalesWorkbookControlData,
    yearlyWorkbookControlData,
    setYearlyWorkbookControlData,
    customerWorkbookControlData,
    setCustomerWorkbookControlData,
    workbookControlData,
    setWorkbookControlData,
    viewType,
    impersonatorInfo,
    sldWeekly,
    sicWeekly,
    sipWeekly,
    isReset,
    filters,
    setFilters,
    previousTimeframe,
    currentTimeframe,
    filterPageOpen,
    embedRefreshTrigger,
    weekSelection,
    supplierPerformanceBasicWorkbookControlData,
    setSupplierPerformanceBasicWorkbookControlData,
    supplierPerformanceInsightsWorkbookControlData,
    setSupplierPerformanceInsightsWorkbookControlData,
    embedDidLoad,
    setEmbedDidLoad,
    filterPageData,
  } = context;

  const pluginConfig = { 'download-enable': 'true', 'page-key': page };

  // get inputs and outputs and put transformations in functions, call getters for each report
  // CREATED STATE VARIABLES TO CAPTURE EMBED URL
  const [src, setSrc] = useState(null);
  const [srcLoading, setSrcLoading] = useState(true);
  const [secondarySrc, setSecondarySrc] = useState(null); // Secondary embed for embeds that have two iframes
  const [secondarySrcLoading, setSecondarySrcSrcLoading] = useState(true);
  const [pepper, setPepper] = useState(null);
  const [sipPepper, setSipPepper] = useState(null);
  const [formattedFilterState, setFormattedFiltersState] = useState(null);
  const [sldObject, setSldObject] = useState({ week: null, vendor: null });
  const [sipObject, setSipObject] = useState({
    week: null,
    vendor: null,
    asysCode: null,
    asysCodeDesc: null,
    region: null,
    area: null,
    market: null,
  });

  const loadRef = useRef(0);
  const oldSldP = useRef({});
  const oldSipP = useRef({});
  const id = page === 'yearly' ? `${page}1-insights` : page;
  const secondaryId = page === 'yearly' ? `${page}2-insights` : page; //Secondary id for the secondary src events

  const data = useEventListener(filterPageOpen, page);

  // enables bi directional data sharing for yearly reports
  useUpdateWorkbookData(data, page, secondaryId);
  useUpdateWorkbookData(data, page, id);

  // fetches peppers
  useFetchPeppers(page, sldObject, setPepper, impersonatorInfo);
  useFetchPeppers(page, sipObject, setSipPepper, impersonatorInfo);

  const fetchEmbedUrl = async (
    embed,
    worksheetControlConfigInformation,
    roleInformation,
    impersonate,
    setSrc,
    setSrcLoading,
  ) => {
    setSrcLoading(true);
    const src = await usersService.getEmbeddingUrl(
      embed,
      worksheetControlConfigInformation,
      roleInformation,
      impersonate,
    );
    setSrc(src);
    setSrcLoading(false);
  };

  useEffect(() => {
    if (!embedDidLoad || data?.type !== 'update') return;

    if (
      !filterPageOpen &&
      data?.type === 'update' &&
      filterPageData?.type !== 'updateFilter'
    ) {
      if (page === 'home') {
        setHomeWorkbookControlData((homeWorkbookControlData) => {
          if (data.data) {
            homeWorkbookControlData = {
              ...homeWorkbookControlData,
              ...data.data,
            };
          }
          return homeWorkbookControlData;
        });
      } else if (page === 'cdq') {
        setQuickFactsWorkbookControlData((quickFactsWorkbookControlData) => {
          if (data.data) {
            quickFactsWorkbookControlData = {
              ...quickFactsWorkbookControlData,
              ...data.data,
            };
          }
          return quickFactsWorkbookControlData;
        });
      } else if (page === 'vr') {
        setVoidReportWorkbookControlData((voidReportWorkbookControlData) => {
          if (data.data) {
            voidReportWorkbookControlData = {
              ...voidReportWorkbookControlData,
              ...data.data,
            };
          }
          return voidReportWorkbookControlData;
        });
      } else if (page === 'weekly') {
        // isReset was placed here due to graphs didn't load after clearing filters
        setWeeklyWorkbookControlData((weeklyWorkbookControlData) => {
          if (data.data) {
            weeklyWorkbookControlData = {
              ...weeklyWorkbookControlData,
              ...data.data,
            };
          }
          return weeklyWorkbookControlData;
        });
      } else if (page === 'sld') {
        setSldWorkbookControlData((sldWorkbookControlData) => {
          if (data.data) {
            sldWorkbookControlData = {
              ...sldWorkbookControlData,
              ...data.data,
            };
          }
          return sldWorkbookControlData;
        });
      } else if (page === 'sip') {
        setSipWorkbookControlData((sipWorkbookControlData) => {
          if (data.data) {
            sipWorkbookControlData = {
              ...sipWorkbookControlData,
              ...data.data,
            };
          }
          return sipWorkbookControlData;
        });
      } else if (page === 'sif') {
        //adds sif workbook data
        setSifWorkbookControlData((sifWorkbookControlData) => {
          if (data.data) {
            sifWorkbookControlData = {
              ...sifWorkbookControlData,
              ...data.data,
            };
          }
          return sifWorkbookControlData;
        });
      } else if (page === 'purchase') {
        setPurchaseWorkbookControlData((purchaseWorkbookControlData) => {
          if (data.data) {
            purchaseWorkbookControlData = {
              ...purchaseWorkbookControlData,
              ...data.data,
            };
          }
          return purchaseWorkbookControlData;
        });
      } else if (page === 'sales') {
        setSalesWorkbookControlData((salesWorkbookControlData) => {
          if (data.data) {
            salesWorkbookControlData = {
              ...salesWorkbookControlData,
              ...data.data,
            };
          }
          return salesWorkbookControlData;
        });
      } else if (page === 'customer-data') {
        setCustomerWorkbookControlData((customerWorkbookControlData) => {
          if (data.data) {
            customerWorkbookControlData = {
              ...customerWorkbookControlData,
              ...data.data,
            };
          }
          return customerWorkbookControlData;
        });
      } else if (page === 'yearly') {
        setYearlyWorkbookControlData((yearlyWorkbookControlData) => {
          if (data.data) {
            yearlyWorkbookControlData = {
              ...yearlyWorkbookControlData,
              ...data.data,
            };
          }
          return yearlyWorkbookControlData;
        });
      } else if (page === 'fsr') {
        if (viewType === 'basic') {
          setSupplierPerformanceBasicWorkbookControlData(
            (supplierPerformanceBasicWorkbookControlData) => {
              if (data.data) {
                supplierPerformanceBasicWorkbookControlData = {
                  ...supplierPerformanceBasicWorkbookControlData,
                  ...data.data,
                };
              }
              return supplierPerformanceBasicWorkbookControlData;
            },
          );
        } else {
          setSupplierPerformanceInsightsWorkbookControlData(
            (supplierPerformanceInsightsWorkbookControlData) => {
              if (data.data) {
                supplierPerformanceInsightsWorkbookControlData = {
                  ...supplierPerformanceInsightsWorkbookControlData,
                  ...data.data,
                };
              }
              return supplierPerformanceInsightsWorkbookControlData;
            },
          );
        }
      } else {
        setWorkbookControlData((workbookControlData) => {
          if (data.data) {
            workbookControlData = { ...workbookControlData, ...data.data };
          }
          return workbookControlData;
        });
      }
    }

    setSldObject(() => {
      if (
        data &&
        data.data &&
        ('Week-sl' in data.data || 'Vendor-sl' in data.data)
      ) {
        let currSldObj = { ...oldSldP.current };
        const week = data.data['Week-sl'] ? data.data['Week-sl'] : '';
        const vendor = data.data['Vendor-sl'] ? data.data['Vendor-sl'] : '';
        if ('Week-sl' in data.data) {
          currSldObj = { ...currSldObj, ...{ week: week } };
        }
        if (!('week' in currSldObj)) {
          currSldObj = { ...currSldObj, ...{ week: '' } };
        }
        if ('Vendor-sl' in data.data) {
          currSldObj = { ...currSldObj, ...{ vendor: vendor } };
        }
        if (!('vendor' in currSldObj)) {
          currSldObj = { ...currSldObj, ...{ vendor: '' } };
        }

        setSldObject(currSldObj);
        oldSldP.current = currSldObj;
      }
    });
    setSipObject(() => {
      if (
        data &&
        data.data &&
        ('Week-sl-produce' in data.data ||
          'Vendor-sl-produce' in data.data ||
          'Product-Number-sl-produce' in data.data ||
          'Product-Number-Description-sl-produce' in data.data ||
          'Region-sl-produce' in data.data ||
          'Area-sl-produce' in data.data ||
          'Market-sl-produce' in data.data)
      ) {
        let currSipObj = { ...oldSipP.current };
        const week = data.data['Week-sl-produce']
          ? data.data['Week-sl-produce']
          : '';
        const vendor = data.data['Vendor-sl-produce']
          ? data.data['Vendor-sl-produce']
          : '';
        const asysCode = data.data['Product-Number-sl-produce']
          ? decodeURIComponent(data.data['Product-Number-sl-produce'])
          : '';
        const asysCodeDesc = data.data['Product-Number-Description-sl-produce']
          ? data.data['Product-Number-Description-sl-produce']
          : '';
        const region = data.data['Region-sl-produce']
          ? decodeURIComponent(data.data['Region-sl-produce'])
          : '';
        const area = data.data['Area-sl-produce']
          ? decodeURIComponent(data.data['Area-sl-produce'])
          : '';
        const market = data.data['Market-sl-produce']
          ? decodeURIComponent(data.data['Market-sl-produce'])
          : '';

        if ('Week-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ week: week } };
        }
        if (!('week' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ week: '' } };
        }
        if ('Vendor-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ vendor: vendor } };
        }
        if (!('vendor' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ vendor: '' } };
        }
        if ('Product-Number-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ asysCode: asysCode } };
        }
        if (!('asysCode' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ asysCode: '' } };
        }
        if ('Product-Number-Description-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ asysCodeDesc: asysCodeDesc } };
        }
        if (!('asysCodeDesc' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ asysCodeDesc: '' } };
        }
        if ('Region-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ region: region } };
        }
        if (!('region' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ region: '' } };
        }
        if ('Area-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ area: area } };
        }
        if (!('area' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ area: '' } };
        }
        if ('Market-sl-produce' in data.data) {
          currSipObj = { ...currSipObj, ...{ market: market } };
        }
        if (!('market' in currSipObj)) {
          currSipObj = { ...currSipObj, ...{ market: '' } };
        }

        setSipObject(currSipObj);
        oldSipP.current = currSipObj;
      }
    });
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (data?.type === 'load') return;

    if (filterPageOpen && data?.type === 'updateFilter') {
      setFilters((currentFilters) => {
        const [controlId, controlValue] = Object.entries(data.data).pop();
        const newFilters = { ...currentFilters };

        if (!controlValue) {
          delete newFilters[controlId];
        } else {
          newFilters[controlId] = controlValue;
        }

        return newFilters;
      });
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const sigma_iframe = document.getElementById(page);
    if (!sigma_iframe) return () => {};
    if (pepper) {
      const pepperControl = {
        'weekly-score': pepper.pepperWS,
        'vendor-fill-rate': pepper.pepperFR,
        'on-time-delivery': pepper.pepperOT,
      };
      postIframeMessage(sigma_iframe, pepperControl);
    }
  }, [JSON.stringify(pepper), JSON.stringify(data)]);

  useEffect(() => {
    const sigma_iframe = document.getElementById(page);
    if (!sigma_iframe) return () => {};
    if (sipPepper) {
      const pepperControl = {
        'weekly-score-produce': sipPepper.pepperWS,
        'vendor-fill-rate-produce': sipPepper.pepperFR,
      };
      postIframeMessage(sigma_iframe, pepperControl);
    }
  }, [JSON.stringify(sipPepper), JSON.stringify(data)]);

  useEffect(() => {
    if (data?.type === 'load') {
      if (page === 'yearly' && loadRef.current === 0) {
        loadRef.current = loadRef.current + 1;
      } else {
        setEmbedDidLoad(true);
      }
    }
  }, [JSON.stringify(data), viewType, filterPageOpen]);

  useEffect(() => {
    if (page !== 'yearly') {
      // use formatted ref to send data to plugin in supplier performance
      if (page !== 'yearly') {
        //Fix this
        let selectedFilters = {
          ...filters,
          'previous-timeframe': encodeDates(previousTimeframe),
          'current-timeframe': encodeDates(currentTimeframe),
        };

        const toggledFilters = getToggledFilters(viewType, selectedFilters); // to add toggle to end of timeframes (not best way to do this)
        const detaggedFilters = removeTags(
          viewType,
          { ...toggledFilters },
          page,
        ); // to remove tags (basic/insights at the end of control ids)
        const formattedFilters = getFormattedFilters(detaggedFilters);
        // eslint-disable-next-line no-unused-vars
        const { 'previous-timeframe': prev, ...filtersNoPrev } =
          formattedFilters;

        let filtersNoTimeframes = Object.entries(toggledFilters).filter(
          ([key]) => {
            return !key.includes('timeframe');
          },
        );
        filtersNoTimeframes = Object.fromEntries(filtersNoTimeframes);

        const sigma_iframe = document.getElementById(page);
        let filterToSend;
        let filterControl;
        if (!sigma_iframe) return () => {};
        if (page === 'home') {
          filterToSend = {
            ...homeWorkbookControlData,
            'current-timeframe-basic-graphs': encodeURIComponent(
              JSON.stringify({
                'current-timeframe': formattedFilters['current-timeframe'],
              }),
            ),
            'previous-timeframe-basic-graphs': encodeURIComponent(
              JSON.stringify({
                'previous-timeframe': formattedFilters['previous-timeframe'],
              }),
            ),
          };
        } else if (page === 'cdq') {
          filterControl =
            viewType === 'basic' ? 'filter-control-basic' : 'filter-control-1';
          filterToSend = {
            ...quickFactsWorkbookControlData,
            [filterControl]: encodeURIComponent(
              JSON.stringify(formattedFilters),
            ),
          };
        } else if (page === 'vr') {
          filterControl =
            viewType === 'basic'
              ? 'filter-control-vr-basic'
              : 'filter-control-vr';
          filterToSend = {
            ...voidReportWorkbookControlData,
            [filterControl]: encodeURIComponent(
              JSON.stringify(formattedFilters),
            ),
          };
        } else if (page === 'weekly') {
          filterControl =
            viewType === 'basic'
              ? 'filter-control-wi-basic'
              : 'filter-control-wi';
          filterToSend = {
            ...weeklyWorkbookControlData,
            [filterControl]: encodeURIComponent(JSON.stringify(filtersNoPrev)),
            ...filtersNoTimeframes,
          };
        } else if (page === 'customer-data') {
          filterControl = 'filter-control-customer';
          filterToSend = {
            ...customerWorkbookControlData,
            [filterControl]: encodeURIComponent(
              JSON.stringify(formattedFilters),
            ),
          };
        } else if (page === 'sld') {
          filterToSend = sldWorkbookControlData;
        } else if (page === 'sip') {
          filterToSend = sipWorkbookControlData;
        } else if (page === 'sif') {
          filterToSend = sifWorkbookControlData;
        } else if (page === 'purchase') {
          filterToSend = {
            ...purchaseWorkbookControlData,
            'filter-control-purchasing': encodeURIComponent(
              JSON.stringify(formattedFilters),
            ),
          };
        } else if (page === 'sales') {
          filterToSend = salesWorkbookControlData;
        } else if (page === 'fsr') {
          if (viewType === 'basic') {
            filterToSend = {
              ...supplierPerformanceBasicWorkbookControlData,
              'filter-selection-sp': encodeURIComponent(
                JSON.stringify(formattedFilters),
              ),
              'current-timeframe-basic-graphs': encodeURIComponent(
                JSON.stringify({
                  'current-timeframe': formattedFilters['current-timeframe'],
                }),
              ),
              'previous-timeframe-basic-graphs': encodeURIComponent(
                JSON.stringify({
                  'previous-timeframe': formattedFilters['previous-timeframe'],
                }),
              ),
            };
          } else {
            const newWeekSelection = `Last ${weekSelection} Weeks`;
            filterToSend = {
              ...supplierPerformanceInsightsWorkbookControlData,
              'week-sp-insights': encodeURIComponent(newWeekSelection),
            };
          }
        } else {
          filterToSend = workbookControlData;
        }
        postIframeMessage(sigma_iframe, filterToSend);
      }
    } else {
      if (page === 'yearly' && embedDidLoad && formattedFilterState) {
        const sigma_iframe = document.getElementById(id);
        const second_sigma_iframe = document.getElementById(secondaryId);
        if (!sigma_iframe) return () => {};
        if (!second_sigma_iframe) return () => {};

        postIframeMessage(sigma_iframe, yearlyWorkbookControlData);
        postIframeMessage(second_sigma_iframe, yearlyWorkbookControlData);
      }
    }
  }, [
    filterPageOpen,
    embedDidLoad,
    isReset,
    JSON.stringify(formattedFilterState),
    page,
    id,
    src,
    JSON.stringify(purchaseWorkbookControlData),
  ]);

  useEffect(() => {
    setSrcLoading(true);
    setEmbedDidLoad(false);
    const impersonatorValues = Object.values(impersonatorInfo).filter(
      (val) => val !== '',
    );
    const impersonate = Object.values(impersonatorInfo).length > 0;
    const roleInformation =
      impersonatorValues.length === 0 ? {} : impersonatorInfo;
    roleInformation.view = viewType;

    const selectedFilters = {
      ...filters,
      'previous-timeframe': encodeDates(previousTimeframe),
      'current-timeframe': encodeDates(currentTimeframe),
    };

    // 3 different filter states we need for different URLs
    const toggledFilters = getToggledFilters(viewType, selectedFilters); // to add toggle to end of timeframes (not best way to do this)
    const detaggedFilters = removeTags(viewType, { ...toggledFilters }); // to remove tags (basic/insights at the end of control ids)
    const formattedFilters = getFormattedFilters(detaggedFilters);
    // responsible for getting specific payload associated with report (used old SigmaPlugin.js for switch)
    const worksheetControlConfigInformation = getWorksheetControlConfigs(
      page,
      viewType,
      pluginConfig,
      roleInformation,
      detaggedFilters,
    );
    if (!sldWeekly && !sipWeekly && !sicWeekly) {
      let srcKey = '';
      if (
        page === 'sld' ||
        page === 'sip' ||
        page === 'sif' ||
        page === 'compliance' ||
        page === 'home' ||
        page === 'purchase' ||
        page === 'sales' ||
        page === 'customer-data'
      ) {
        srcKey = `${page}`;
      } else {
        srcKey = `${page}-${viewType}`;
      }
      if (page !== 'yearly') {
        fetchEmbedUrl(
          srcKey,
          worksheetControlConfigInformation,
          roleInformation,
          impersonate,
          setSrc,
          setSrcLoading,
        );
      }
    } else if (sipWeekly && page === 'sip') {
      fetchEmbedUrl(
        'sip-weekly-trend',
        worksheetControlConfigInformation,
        roleInformation,
        impersonate,
        setSrc,
        setSrcLoading,
      );
    } else if (sldWeekly && page === 'sld') {
      fetchEmbedUrl(
        'scoring-graphs',
        worksheetControlConfigInformation,
        roleInformation,
        impersonate,
        setSrc,
        setSrcLoading,
      );
    } else if (sicWeekly && page === 'compliance') {
      fetchEmbedUrl(
        'compliance-details',
        worksheetControlConfigInformation,
        roleInformation,
        impersonate,
        setSrc,
        setSrcLoading,
      );
    }

    if (page === 'yearly') {
      fetchEmbedUrl(
        `${page}1-insights`,
        worksheetControlConfigInformation,
        roleInformation,
        impersonate,
        setSrc,
        setSrcLoading,
      );
      fetchEmbedUrl(
        `${page}2-insights`,
        worksheetControlConfigInformation,
        roleInformation,
        impersonate,
        setSecondarySrc,
        setSecondarySrcSrcLoading,
      );
    }

    // propagate value of formatted filters for postMessage to iframe
    setFormattedFiltersState(formattedFilters);
  }, [
    page,
    isReset,
    viewType,
    sldWeekly,
    sipWeekly,
    sicWeekly,
    JSON.stringify(impersonatorInfo),
    JSON.stringify(filters),
    previousTimeframe,
    currentTimeframe,
    embedRefreshTrigger,
  ]);

  return (
    <>
      <Flex direction={'row'} width={'100%'} height={'100%'}>
        <div className="embed-container">
          {srcLoading ? (
            <Loading />
          ) : (
            <SigmaIframe page={page} id={id} src={src} />
          )}
        </div>
        {secondarySrc ? (
          <div className="embed-container">
            {secondarySrcLoading ? (
              <Loading />
            ) : (
              <SigmaIframe page={page} id={secondaryId} src={secondarySrc} />
            )}
          </div>
        ) : null}
      </Flex>
    </>
  );
}
