import React, { useContext } from "react";
import { Flex } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import { SigmaEmbed } from '../../../layouts/SigmaEmbed';
import Dev from '../../dev/Dev';
import { FiltersContext } from '../../../components/Navigation/Routes';
import featureToggle from '../../../featureToggle.json';

export const Compliance = ({ page }) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const context = useContext(FiltersContext);
  const { setSicWeekly } = context;

  if (featureToggle[environment][page]) {
    return (
      <Flex
        direction={'column'}
        width={'100%'}
        height={'100%'}
        overflow={'hidden'}
        paddingTop={'12px'}
      >
        <Flex direction={'row'} style={{ marginInlineStart: '20px' }}>
          <Button
            onClick={() => setSicWeekly(false)}
            className="information-buttons"
          >
            SUMMARY
          </Button>
          <Button
            onClick={() => setSicWeekly(true)}
            className="information-buttons"
          >
            FEE DETAILS
          </Button>
        </Flex>
        <SigmaEmbed page={page} />
      </Flex>
    )
  } else {
    return <Dev />;
  }
};
